var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{class:_vm.editable ? 'editable-form': 'non-editable'},[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-end"},[_c('div',{staticClass:"button-items"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.toggleEditable}},[_vm._v(" "+_vm._s(_vm.editable ? "Disable Edit" : "Enable Edit")+" ")])],1)])]),_c('stepOne',{ref:"stepOne"}),_c('stepTwo',{ref:"stepTwo"}),_c('stepThree',{ref:"stepThree"}),((_vm.isDataLoaded && _vm.userDetail.accountStatus.account_status == 'Submitted') || (_vm.isDataLoaded &&_vm.userDetail.accountStatus.account_status == 'Approved' ) || (_vm.isDataLoaded &&_vm.userDetail.accountStatus.account_status == 'Repaired' ))?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-end"},[_c('div',{staticClass:"button-items"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.updateForm}},[(_vm.updateFormLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Update Form")],1),_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.viewActions}},[_vm._v("Form Actions")])],1)])]):_vm._e(),_c('b-modal',{ref:"modal-actions",attrs:{"id":"modal-actions","scrollable":"","title":"Please Select Appropriate Action","title-class":"font-14"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.submitStatus}},[_vm._v(" Submit ")])]}}])},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required"},[_vm._v("Status")]),_c('b-form-select',{staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.formAction.status.$error,
        },attrs:{"options":_vm.statusList},model:{value:(_vm.formAction.status),callback:function ($$v) {_vm.$set(_vm.formAction, "status", $$v)},expression:"formAction.status"}}),(_vm.submitted && _vm.$v.formAction.status.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formAction.status.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"required",attrs:{"for":"remarks"}},[_vm._v("Remark")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formAction.remarks),expression:"formAction.remarks"}],staticClass:"form-control",class:{
          'is-invalid': _vm.submitted && _vm.$v.formAction.remarks.$error,
        },attrs:{"id":"remarks","type":"text","name":"remarks"},domProps:{"value":(_vm.formAction.remarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formAction, "remarks", $event.target.value)}}}),(_vm.submitted && _vm.$v.formAction.remarks.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formAction.remarks.required)?_c('span',[_vm._v("This field is required.")]):_vm._e()]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }